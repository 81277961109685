import React from "react";

function Title() {
  return (
    <div className="row mt-5 justify-content-center">
      <div className="col-lg-6 col-md-8 text-center">
        <h1>TITRE PROVISOIRE</h1>
      </div>
    </div>
  )
}

export default Title;