import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

import "./Navbar.css";

function Navbar() {
  const { currentUser, logOut } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  async function handleLogOut() {
    try {
      await logOut();
      navigate("/admin");
    } catch {
    }
  }

  if (currentUser) {
    return (
      <nav className="navbar navbar-dark navbar-expand-lg sticky-top bg-dark">
        <div className="container-fluid">
          <Link to="/entries" className={ `navbar-brand text-white ${ location.pathname.includes("home") ? "active" : "" }` }>TITRE PROVISOIRE</Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/entries" className={ `nav-link text-white ${ location.pathname.includes("entries") ? "active" : "" }` }>Entries</Link>
              </li>
              {/* <li className="nav-item">
                <Link to="/authors" className={ `nav-link text-white ${ location.pathname.includes("authors") ? "active" : "" }` }>Authors</Link>
              </li> */}
              <li className="nav-item">
                <Link to="/gps-recorder" className={ `nav-link text-white ${ location.pathname.includes("gps-recorder") ? "active" : "" }` }>GPS Recorder</Link>
              </li>
              <li className="nav-item">
                <Link to="/geolocations" className={ `nav-link text-white ${ location.pathname.includes("geolocations") ? "active" : "" }` }>Geolocations</Link>
              </li>
              <li className="nav-item">
                <Link onClick={handleLogOut} className="nav-link text-white">Logout</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  } else {
    return <></>
  }

  
}

export default Navbar;