import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuth } from "../../contexts/AuthContext";
import "./Login.css";

function Login() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { logIn, currentUser } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate("/entries");
    }
  }, []);

  async function submitLogIn(data) {
    try {
      setError("");
      setLoading(true);
      await logIn(data.email, data.password);
      navigate("/entries");
    } catch (err) {
      setError("Échec de la connexion");
    }
    setLoading(false);
  }

  return (
    <div className="row justify-content-center mt-5">
      <div className="col-lg-6 col-md-12 mt-5">

        <h1 className="text-center mt-5"><Link to="/" className="text-light text-decoration-none">TITRE PROVISOIRE</Link></h1>
        <p className="text-center text-secondary">Admin panel</p>

        { error && <div className="alert alert-danger text-danger bg-dark border-danger mt-1">💥 { error }</div> }

        <form className="mt-5">
          <label htmlFor="email" className="form-label mt-1">Email</label>
          <input 
            type="email"
            id="email"
            className={ `form-control text-bg-dark border-light ${ errors.email && "is-invalid border-danger" }` }
            placeholder="bonjour@titreprovisoi.re"
            { ...register("email", { required: true, pattern: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i }) }
          />
          { errors.email && <div className="form-text text-danger">Enter email address</div> }

          <label htmlFor="password" className="form-label mt-3">Password</label>
          <input 
            type="password"
            id="password"
            className={ `form-control text-bg-dark border-light ${ errors.password && "is-invalid border-danger" }` }
            placeholder="······"
            { ...register("password", { required: true, minLength: 6 }) }
          />
          { errors.password && <div className="form-text text-danger">Password must be at least 6 charaecters long</div> }
          
        
          <div className="d-grid gap-2">
            <button className={ `btn mt-5 btn-outline-light` } onClick={ handleSubmit(submitLogIn) } disabled={ loading } type="submit">Login</button>
          </div>
        </form>

        <div className=" bottom-margin"></div>
      </div>
    </div>
  )
}

export default Login;