import React from "react";

function Intro() {
  return (
    <div className="row mt-5 justify-content-center">
      <div className="col-lg-6 col-md-8">

        <h3>Qu'est-ce que c'est ?</h3>

        <p>
          Titre Provisoire est une <mark>revue temporaire située</mark>. Elle a été fondée par <a href="https://mlav.land/" className="text-light">MLAV.LAND</a> en 2023.
        </p>

        <ol>
          <li>Titre Provisoire est édité à une centaine d'exemplaires mensuellement.</li>
          <li>Titre Provisoire est imprimé par un procédé d'impression thermique sur papier autocollant.</li>
          <li>Titre Provisoire est affiché dans l'espace public parisien.</li>
        </ol>

        <h3 className="mt-5">Appel à contributions</h3>

        <p>
          Tout est provisoire. Nos certitudes, nos peurs, nos désirs, nos vies. Les états éphémères se succèdent, les états 'nouveaux' ne sont que conventions. Combien de temps dure le 'nouveau', le 'provisoire' ?
        </p>

        <p>
          Nous le savons depuis 1789, année révolutionnaire, ‘rien ne se perd, rien ne se crée, tous se transforme’. Néanmoins, nous sommes voué·es à naviguer au travers de définitions figées, illusions de stabilité données par le poids des mots. La définition du réel est une course impossible semblable au châtiment de Sisyphe, une accumulation de signes figés que nous ne pouvons remettre en mouvement qu’en les requestionnant.
        </p>

        <p>
          Seul le temps nous enveloppe et nous dépassera tous·tes.Seul le temps, faisant s’écouler les choses, porte en lui la valeur de nos vies. L’idée coloniale d’accaparement de nos vies par la conquête de nos espaces s’épuise, ne laissant plus que de mince bastillons à défendre. C’est du temps qu’elle s’empare.
        </p>

        <p>
          Donner un sens à l’Histoire, c’est coloniser le temps par la force des mots. C’est de cette manière que le pouvoir annexe l’espace et le temps. La domination de nos corps passe par l’Histoire. La domination, de même que la révolte, passe par l’appropriation du droit à écrire l’Histoire. Elle est plurielle, ouverte, fugace, c’est une Fiction à écrire.
        </p>

        <p>
          Notre ambition est d’ouvrir un espace d’expression, de réflexion et de recherche, autour de la question de l’éphémère, du provisoire, du transitoire, dans tous les domaines : philosophie, poésie, société, architecture, arts, sciences, etc.
        </p>

        <p>
          Titre Provisoire ouvre un premier appel à contributions pour répondre à ces questionnements à partir de maintenant et jusqu’au 15 juin 2023. La revue sera mensuellement éditée à une centaine d’exemplaires par procédé d’impression thermique sur papier autocollant, et affichée dans l’espace public parisien. Cette parenthèse que nous ouvrons se refermera au gré de ses lecteurs·ices, le titre que nous lui donnons est provisoire, son contenu, passager.
        </p>

        <h3 className="mt-5">Soumettre votre contribution</h3>

        <p>
          Titre Provisoire est ouvert à toutes les contributions.
          Il est possible d'envoyer plusieurs textes. Le premier appel à contributions est fixé jusqu'au <mark>15 juin 2023</mark>.
        </p>

        <p>
          Votre contribution ne doit pas contenir plus de <mark>2000 caractères</mark> (espaces compris), et le titre un maximum de 25 caractères.
        </p>

        <p>
          Pour nous envoyer votre contribution, merci d'utiliser le formulaire ci-dessous. Veuillez choisir si vous souhaitez signer ou non votre contribution. Dans le cas où vous choisissez de rester anonyme, vous pouvez nous transmettre votre adresse email que nous utiliserons uniquement pour vous informer des suites du projet.
        </p>
      </div>
    </div>
  )
}

export default Intro;