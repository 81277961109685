import React, { useState } from "react";
import { db } from "../../config/firebase";
import { doc, updateDoc } from "firebase/firestore";

import formatDate from "../../helpers/formatDate";

import "./ListItem.css";

function ListItem({ entry, setOpenEntryModal, setSelectedEntry, setReload }) {
  const [localEntry, setLocalEntry] = useState(entry);

  function handleOpenModal() {
    setOpenEntryModal(true);
    setSelectedEntry(localEntry);
  }

  async function updateEntry(property) {
    await updateDoc(doc(db, "entries", entry.id), {
      [property]: !localEntry[property]
    });
    setLocalEntry({ ...localEntry, [property]: !localEntry[property] });
    setReload(prev => !prev);
  }

  return (
    <tr>
      <td onClick={handleOpenModal} className="pointer">
        {localEntry.title}
      </td>
      <td className="text-end" style={{ width: "20%" }}>
        {formatDate(localEntry.createdAt)}
      </td>
      <td className="text-end" style={{ width: "10%" }}>
        <span
        className={`btn btn-xs ${ localEntry.read ? "btn-success" : "btn-outline-secondary" }`}
        onClick={() => updateEntry("read")}
        >
          READ
        </span>
      </td>
      <td className="text-end" style={{ width: "10%" }}>
        <span
          className={`btn btn-xs ${ localEntry.selected ? "btn-success" : "btn-outline-secondary" }`}
          onClick={() => updateEntry("selected")}
        >
          SELECTED
        </span>
      </td>
      <td className="text-end" style={{ width: "10%" }}>
        <span
          className={`btn btn-xs ${ localEntry.published ? "btn-primary" : "btn-outline-secondary" }`}
          onClick={() => updateEntry("published")}
        >
          PUBLISHED
        </span>
      </td>
      <td className="text-end" style={{ width: "10%" }}>
        <span
          className={`btn btn-xs ${ localEntry.archived ? "btn-danger" : "btn-outline-secondary" }`}
          onClick={() => updateEntry("archived")}
        >
          ARCHIVED
        </span>
      </td>
    </tr>
  );
}

export default ListItem;