import React, { useState } from "react";

import formatDate from "../../helpers/formatDate";

import "./ListItem.css";

function ListItem({ geolocation, setOpenDeleteModal, setOpenUpdateModal, setSelectedGeolocation }) {
  const [localGeolocation, setLocalGeolocation] = useState(geolocation);

  function handleOpenDeleteModal() {
    setOpenDeleteModal(true);
    setSelectedGeolocation(localGeolocation);
  }

  function handleOpenUpdateModal() {
    setOpenUpdateModal(true);
    setSelectedGeolocation(localGeolocation);
  }

  return (
    <tr>
      <td style={{ width: "10%" }}>
        { localGeolocation.issueId }
      </td>

      <td className="" style={{ width: "50%" }}>
        <a 
          href={`https://www.google.fr/maps?q=${localGeolocation.latlong}`} 
          className="text-light" 
          target="_blank" 
          rel="noreferrer"
        >
          { localGeolocation.latlong }
        </a>
      </td>

      <td style={{ width: "20%" }}>
        { formatDate(localGeolocation.createdAt) }
      </td>

      <td className="text-end" style={{ width: "5%" }}>
        <button
          className="btn btn-xs btn-outline-secondary"
          onClick={ handleOpenUpdateModal }
        >
          Modify
        </button>
      </td>

      <td className="text-end" style={{ width: "5%" }}>
        <button
          className="btn btn-xs btn-outline-danger"
          onClick={ handleOpenDeleteModal }
        >
          Delete
        </button>
      </td>
    </tr>
  );
}

export default ListItem;