import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { db } from "../../config/firebase";
import { doc, setDoc } from "firebase/firestore";
import { v4 } from "uuid";

function GpsRecorder() {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const [latlong, setLatLong] = useState("");

  const [error, setError] = useState("");

  async function createGeolocationRecord(data) {
    if (latlong !== "") {
      const geolocationUid = v4();
      const createdAt = new Date().toISOString();
      await setDoc(doc(db, "geolocations", geolocationUid), {
        id: geolocationUid,
        issueId: data.issueId,
        latlong: latlong,
        createdAt: createdAt
      });
      setLatLong("");
      setError("");
    } else {
      setError("You must retrieve your coordinates before adding a new record. Please click 'Get current position', allow the browser to locate you and try again.")
    }
  }

  function getCurrentPosition() {
    function success(position) {
      const latitude  = position.coords.latitude;
      const longitude = position.coords.longitude;
      setLatLong(latitude + ", " + longitude);
      setError("");
    }
    function error() {
      console.log("Could not get current position")
    }
    navigator.geolocation.getCurrentPosition(success, error);
  }

  return (
    <div className="row justify-content-center mt-5">
      <div className="col-lg-6 col-md-12 mt-5">

        <h1 className="text-center mt-5">GPS Recorder</h1>

        <div className="d-grid gap-2">
          <button className={ `btn mt-3 btn-outline-light` } onClick={ getCurrentPosition }>Get current position</button>
        </div>

        <form className="mt-3">
          <label htmlFor="latlong" className="form-label mt-3">Latitude, longitude</label>
          <input 
            type="latlong"
            id="latlong"
            className={ `form-control text-bg-dark ${ errors.latlong ? "is-invalid border-danger" : "border-light" }` }
            placeholder="Latitude, longitude"
            value={ latlong }
            { ...register("latlong", { required: true, pattern: /^(\-?\d{1,3}\.\d+)[,|\s]\s*(\-?\d{1,3}\.\d+)$/ }) }
          />
          
          <label htmlFor="issueId" className="form-label mt-3">Issue ID</label>
          <input 
            type="issueId"
            id="issueId"
            className={ `form-control text-bg-dark ${ errors.issueId ? "is-invalid border-danger" : "border-light" }` }
            placeholder="4"
            { ...register("issueId", { required: true }) }
          />

          <div className="d-grid gap-2">
            <button className={ `btn mt-5 btn-outline-light` } onClick={ handleSubmit(createGeolocationRecord) } type="submit">Add GPS Record</button>
          </div>

          {
            error &&
            <p className="mt-3 text-danger lh-sm fs-6">{ error }</p>
          }
        </form>
      </div>
    </div>
  )
}

export default GpsRecorder;