import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoute from "./components/Auth/PrivateRoute";
import { AuthProvider } from "./contexts/AuthContext";

import './App.css';

import Index from "./components/Index/Index";
import Navbar from "./components/Navbar/Navbar";
import Login from "./components/Auth/Login";
import Entries from "./components/Entries/List";
import GpsRecorder from "./components/GpsRecorder/GpsRecorder";
import Geolocations from "./components/Geolocations/List";

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="container">

          <Navbar />

          <Routes>
            <Route exact path="/" element={ <Index /> } />
            <Route exact path="/admin" element={ <Login /> } />
            <Route exact path="/entries" element={ <PrivateRoute><Entries /></PrivateRoute> } />
            <Route exact path="/gps-recorder" element={ <PrivateRoute><GpsRecorder /></PrivateRoute> } />
            <Route exact path="/geolocations" element={ <PrivateRoute><Geolocations /></PrivateRoute> } />
            <Route path="*" element={ <h1>Error</h1> } />
          </Routes>

        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
