import React from "react";

import Title from "./Title";
import Intro from "./Intro";
import SubmitEntry from "./SubmitEntry";
import Contact from "./Contact";

import "./Index.css";

function Index() {
  return (
    <>
      <Title />

      <Intro />

      <SubmitEntry />

      <Contact />
    </>
  )
}

export default Index;