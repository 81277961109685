import React from "react";

function ListFilter({ filters, setFilters }) {

  const handleFilter = name => 
    setFilters(prevState => ({ ...prevState, [name]: !filters[name] }));

  const filterTypes = [
    { name: "unread", label: "Unread" },
    { name: "read", label: "Read" },
    { name: "selected", label: "Selected" },
    { name: "published", label: "Published" },
    { name: "archived", label: "Archived" }
  ];

  return (
    <h5 className="text-center mb-5">
      {filterTypes.map(({ name, label }) => (
        <span
          key={name}
          className={`btn btn-sm me-2 ${filters[name] ? "btn-light" : "btn-outline-light"}`}
          onClick={() => handleFilter(name)}
        >
          {label}
        </span>
      ))}
    </h5>
  );
}

export default ListFilter;