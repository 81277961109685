import React, { useState } from "react";

function ListFilter({ setFilter }) {
  const [inputValue, setInputValue] = useState('');

  const handleChange = (event) => {
    setInputValue(event.target.value);
    setFilter(event.target.value);
  }

  return (
    <div className="row justify-content-center">
      <div className="col-lg-6 col-md-12 float-center mb-5">
          <input 
            value={inputValue} 
            onChange={handleChange}
            placeholder="Filter by ID"
            className="form-control text-bg-dark"
          />
      </div>
    </div>
  );
}

export default ListFilter;