import React from "react";

import formatDate from "../../helpers/formatDate";
import "./EntryModal.css";

function EntryModal(props) {
  const { setOpenEntryModal, setReload, selectedEntry } = props;

  function handleClick(e) {
    if (e.target.classList.contains('backdrop')) {
      setOpenEntryModal(false);
    }
  }

  function copyTitleToClipboard() {
    navigator.clipboard.writeText(selectedEntry.title)
  }

  function copyAuthorToClipboard() {
    if (selectedEntry.fullName) {
      navigator.clipboard.writeText(selectedEntry.fullName)
    }
    navigator.clipboard.writeText("Anonyme")
  }

  function copyEmailToClipboard() {
    navigator.clipboard.writeText(selectedEntry.email)
  }

  function copyDateToClipboard() {
    navigator.clipboard.writeText(formatDate(selectedEntry.createdAt))
  }

  function copyContentToClipboard() {
    navigator.clipboard.writeText(selectedEntry.content)
  }

  return (
    <div className="backdrop" onClick={ handleClick }>
      <div className="buttons">
        <button onClick={copyTitleToClipboard} className="btn btn-light btn-sm mt-2"><i class="fa-regular fa-clone"></i> Title</button><br/>
        <button onClick={copyAuthorToClipboard} className="btn btn-light btn-sm mt-2"><i class="fa-regular fa-clone"></i> Author</button><br/>
        {
          selectedEntry.email &&
          <><button onClick={copyEmailToClipboard} className="btn btn-light btn-sm mt-2"><i class="fa-regular fa-clone"></i> Email</button><br/></>
        }
        <button onClick={copyDateToClipboard} className="btn btn-light btn-sm mt-2"><i class="fa-regular fa-clone"></i> Date</button><br/>
        <button onClick={copyContentToClipboard} className="btn btn-light btn-sm mt-2"><i class="fa-regular fa-clone"></i> Content</button>
      </div>
      <div className="card card-entry text-bg-light border-secondary p-4">
        <h4>{ selectedEntry.title }</h4>
        <p className="mb-5">
          <i>
            {
              selectedEntry.fullName && selectedEntry.email &&
              <>par <a href={`mailto:${selectedEntry.email}`} className="text-dark">{selectedEntry.fullName}</a></>
            }
            {
              selectedEntry.fullName && !selectedEntry.email &&
              <>par {selectedEntry.fullName}</>
            }
            {
              !selectedEntry.fullName && selectedEntry.email &&
              <><a href={`mailto:${selectedEntry.email}`} className="text-dark">Anonyme</a></>
            }
            {
              !selectedEntry.fullName && !selectedEntry.email &&
              <>Anonyme</>
            }
          </i>
            , le { formatDate(selectedEntry.createdAt) }
        </p>
        <p className="small">{ selectedEntry.content }</p>
      </div>
    </div>
  )
};

export default EntryModal;