import React from "react";
import { useForm } from "react-hook-form";
import { db } from "../../config/firebase";
import { doc, updateDoc } from "firebase/firestore";

function UpdateModal({setOpenUpdateModal, setReload, selectedGeolocation}) {

  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: { latlong: selectedGeolocation.latlong, issueId: selectedGeolocation.issueId }
  });

  function handleClick(e) {
    if (e.target.classList.contains('backdrop')) {
      setOpenUpdateModal(false);
    }
  }

  async function updateGeolocation(data) {
    await updateDoc(doc(db, "geolocations", selectedGeolocation.id), {
      latlong: data.latlong,
      issueId: data.issueId
    });
    setOpenUpdateModal(false);
    setReload(prev => !prev);
  }

  return (
    <div className="backdrop" onClick={ handleClick }>
      <div className="card update-modal text-bg-dark border-light p-4">
        <h4>Update geolocation</h4>
        
        <form>
          <label htmlFor="latlong" className="form-label mt-3">Latitude, longitude</label>
          <input 
            type="text"
            id="latlong"
            className={ `form-control text-bg-dark ${ errors.latlong && "is-invalid border-danger" }` }
            placeholder="Latitude, longitude"
            { ...register("latlong", { required: true, pattern: /^(\-?\d{1,3}\.\d+)[,|\s]\s*(\-?\d{1,3}\.\d+)$/ }) }
          />

          { errors.latlong && <div className="form-text text-danger small">Please enter valid coordinates</div> }

          <label htmlFor="issueId" className="form-label mt-3">Issue ID</label>
          <input 
            type="text"
            id="issueId"
            className={ `form-control text-bg-dark ${ errors.issueId && "is-invalid border-danger" }` }
            placeholder="Issue ID"
            { ...register("issueId", { required: true }) }
          />

          { errors.issueId && <div className="form-text text-danger">Please enter an issue ID</div> }

          <button className="btn btn-sm btn-outline-light float-end mt-4" onClick={ handleSubmit(updateGeolocation) } type="submit">Modify geolocation</button>
        </form>
      </div>
    </div>
  )
};

export default UpdateModal;