import React from "react";
import "./Alert.css";

function Alert(props) {
  const { message, setSuccess } = props;

  return (
    <div className="alert alert-dark alert-dismissible fade show mt-3" role="alert">
      { message }
      <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={ (e) => { setSuccess(false) } }></button>
    </div>
  )
}

export default Alert;