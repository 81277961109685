import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { db } from "../../config/firebase";
import { doc, setDoc } from "firebase/firestore";
import { v4 } from "uuid";

import Alert from "./Alert";

function SubmitEntry() {
  const { register, handleSubmit, watch, unregister, reset, formState: { errors } } = useForm();
  const watchAnonymousCheck = watch("anonymousCheck");
  const watchTitleLength = watch("title");
  const watchTextLength = watch("content");
  const [countTitle, setCountTitle] = useState(0);
  const [countText, setCountText] = useState(0);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (watchAnonymousCheck) {
      register("fullName");
    } else {
      unregister("fullName");
    }
  }, [register, unregister, watchAnonymousCheck]);

  useEffect(() => {
    setCountTitle(watchTitleLength ? watchTitleLength.length : 0);
    setCountText(watchTextLength ? watchTextLength.length : 0);
  }, [watchTextLength, watchTitleLength]);

  async function createEntry(data) {
    const entryUid = v4();
    const createdAt = new Date().toISOString();

    const entryData = {
      id: entryUid,
      email: data.email,
      anonymousCheck: data.anonymousCheck,
      title: data.title,
      content: data.content,
      createdAt: createdAt,
      read: false,
      selected: false,
      published: false,
      ...(data.fullName && {fullName: data.fullName}),      
    };
  
    await setDoc(doc(db, "entries", entryUid), entryData);
    setSuccess(true);
    reset();
  }

  return (
    <div className="row mb-5 justify-content-center">
      <div className="col-lg-6 col-md-6">

      <form>

        <label htmlFor="title" className="form-label mt-3">Titre</label>
        <input 
          type="title"
          id="title"
          className={ `form-control text-bg-dark ${ errors.title ? "is-invalid border-danger" : "border-light" }` }
          placeholder="ALIQUAM ORNARE"
          maxLength="25"
          onChange={e => {
            e.target.value = e.target.value.toUpperCase();
          }}
          onInput={e => {
            e.target.value = e.target.value.toUpperCase();
          }}
          { ...register("title", { required: true, maxLength: 25 }) }
        />

        <p className="text-end mb-0 small text-secondary">{ countTitle }/25</p>

        { errors.title && <p className="text-danger text-center small">Merci d'entrer un titre.</p> }

        <label htmlFor="content" className="form-label mt-1">Contribution</label>
        <textarea 
          type="text"
          id="content"
          rows="10"
          className={ `form-control text-bg-dark ${ errors.content ? "is-invalid border-danger" : "border-light" }` }
          placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          maxLength="2000"
          { ...register("content", { required: true, maxLength: 2000 }) }
        />

        <p className="text-end mb-0 small text-secondary">{ countText }/2000</p>

        { errors.content && <p className="text-danger text-center small">Merci d'entrer votre texte de contribution.</p> }

        <label htmlFor="email" className="form-label mt-1">Email</label>
        <input 
          type="email"
          id="email"
          className={ `form-control text-bg-dark ${ errors.email ? "is-invalid border-danger" : "border-light" }` }
          placeholder="email@domain.com"
          { ...register("email", { pattern: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i }) }
        />

        <div className="form-check mt-3">
          <input
            type="checkbox"
            id="anonymousCheck"
            className={ `form-check-input text-bg-dark ${ errors.anonymousCheck ? "is-invalid border-danger" : "border-light" }` }
            {...register("anonymousCheck")}
          />
          <label className="form-check-label" htmlFor="flexCheckDefault">
            Je souhaite signer la contribution
          </label>
        </div>

        { watchAnonymousCheck ? (
          <>
            <label htmlFor="fullName" className="form-label mt-2">Nom complet</label>
            <input 
              type="text"
              id="fullName"
              className={ `form-control text-bg-dark ${ errors.fullName ? "is-invalid border-danger" : "border-light" }` }
              placeholder="John Doe"
              maxLength="46"
              { ...register("fullName", { maxLength: 46 }) }
            />
          </>
        ) : null }

        <div className="d-grid gap-2">
          <button className={ `btn mt-4 btn-outline-light` } onClick={ handleSubmit(createEntry) } type="submit">Envoyer la contribution</button>
        </div>

      </form>

      {
        success && 
        <Alert message="Votre contribution a bien été transmise. Merci !" setSuccess={ setSuccess } />
      }
      
      </div>
    </div>
  )
}

export default SubmitEntry;