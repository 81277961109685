import React, { useEffect, useState } from "react";
import { db } from "../../config/firebase";
import { collection, query, getDocs } from "firebase/firestore";

import sortByCreationDate from "../../helpers/sortByCreationDate";

import ListItem from "./ListItem";
import ListFilter from "./ListFilter";
import EntryModal from "./EntryModal";

function ListEntries() {
  const [entries, setEntries] = useState();
  const [reload, setReload] = useState(false);
  const [filters, setFilters] = useState({ unread: false, read: false, selected: false, published: false, archived: false });
  const [openEntryModal, setOpenEntryModal] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState();

  useEffect(() => {
    getEntries()
  }, [reload])

  async function getEntries() {
    const q = query(collection(db, "entries"));
    const querySnapshot = await getDocs(q);
    const arr = []
    querySnapshot.forEach((doc) => {
      arr.push(doc.data())
    });
    sortByCreationDate(arr);
    setEntries(arr);
  }

  return (
    <div className="row mt-5 justify-content-center">
      <div className="col-lg-10 col-md-12">
        
        <ListFilter filters={ filters } setFilters={ setFilters } />
        
        <table className="table text-light">
          <tbody>
            {
              entries &&
              entries
                .filter(entry => !entry.archived || filters.archived)
                .filter(entry => !filters.unread || !entry.read)
                .filter(entry => !filters.read || entry.read)
                .filter(entry => !filters.selected || entry.selected)
                .filter(entry => !filters.published || entry.published)
                // .filter(entry => !filters.archived || entry.archived)
                .map(entry => <ListItem key={ entry.id } entry={ entry } setOpenEntryModal={ setOpenEntryModal } setSelectedEntry={ setSelectedEntry } setReload={ setReload } />)
            }
          </tbody>
        </table>

        { openEntryModal && (
          <EntryModal selectedEntry={ selectedEntry } setOpenEntryModal={ setOpenEntryModal } setReload={ setReload } />
        )}
      </div>
    </div>
  )
}

export default ListEntries;