import React, { useEffect, useState } from "react";
import { db } from "../../config/firebase";
import { collection, query, getDocs } from "firebase/firestore";

import sortByIssueId from "../../helpers/sortByIssueId";

import ListItem from "./ListItem";
import ListFilter from "./ListFilter";
import DeleteModal from "./DeleteModal";
import UpdateModal from "./UpdateModal";

function List() {
  const [geolocations, setGeolocations] = useState();
  const [reload, setReload] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [selectedGeolocation, setSelectedGeolocation] = useState();
  const [filter, setFilter] = useState("");

  useEffect(() => {
    getGeolocations()
  }, [reload])

  async function getGeolocations() {
    const q = query(collection(db, "geolocations"));
    const querySnapshot = await getDocs(q);
    const arr = []
    querySnapshot.forEach((doc) => {
      arr.push(doc.data())
    });
    sortByIssueId(arr);
    setGeolocations(arr);
  }

  return (
    <div className="row mt-5 justify-content-center">
      <div className="col-lg-10 col-md-12">

        <ListFilter setFilter={ setFilter } />

        <table className="table text-light">
          <tbody>
            {
              geolocations &&
              geolocations
                .filter(geolocation => geolocation.issueId.includes(filter))
                .map(geolocation => (
                  <ListItem 
                    key={geolocation.id} 
                    geolocation={geolocation} 
                    setOpenDeleteModal={setOpenDeleteModal}
                    setOpenUpdateModal={setOpenUpdateModal}
                    setSelectedGeolocation={setSelectedGeolocation}
                  />)
              )
            }
          </tbody>
        </table>

        { openDeleteModal && (
          <DeleteModal 
            selectedGeolocation={ selectedGeolocation } 
            setOpenDeleteModal={ setOpenDeleteModal } 
            setReload={ setReload }
          />
        )}

        { openUpdateModal && (
          <UpdateModal 
            selectedGeolocation={ selectedGeolocation }
            setOpenUpdateModal={ setOpenUpdateModal }
            setReload={ setReload }
          />
        )}
      </div>
    </div>
  )
}

export default List;