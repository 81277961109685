import React from "react";

function Intro() {
  return (
    <div className="row mt-5 justify-content-center">
      <div className="col-lg-6 col-md-8 mb-5">

        <h3>Contact</h3>

        <p className="mb-5">
          Pour toute question, merci de nous contacter à l'adresse suivante : <a href="mailto:bonjour@titreprovisoi.re" className="text-light">bonjour@titreprovisoi.re</a>.
        </p>

        <h3>Mentions légales</h3>

        <p className="mb-5">
          Les contributions feront l'objet d'une sélection.
          Si votre contribution est sélectionnée pour être publiée, vous en serez tenu·e informé·e par email.
          En envoyant votre contribution, vous acceptez de déléguer à Titre Provisoire la diffusion de votre texte en déléguant le droit de reproduire, de représenter, et de communiquer votre œuvre au public.
          La cession de ces droits est réalisée sans contrepartie financière.
        </p>

      </div>
    </div>
  )
}

export default Intro;